import { Button, Container } from "@mantine/core";
import { useNavigate } from "@remix-run/react";

export default function NotFound() {
	const navigate = useNavigate();
	return (
		<Container>
			<h1>Halaman tidak ditemukan</h1>
			<Button onClick={() => navigate("/")}>Kembali</Button>
		</Container>
	);
}
